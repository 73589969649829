import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./two.scss";
import { useWindowSize } from "../../../hooks/useWindowHeight";

const ChapterTwo = () => {

    const size = useWindowSize();

    const h2Styles = {
        component: "h2", 
        variant: "homeH2", 
        // color: "primary.light"

    }
    return (
        <>
            <div className="title-two frame center-screen">

                <Box maxWidth='1300px' mx='auto' >


                    <Box display='flex' mb='1.5em' flexWrap='wrap' justifyContent='center'>
                        <Box flexBasis='100%' display='flex' mb='1em' justifyContent='center'>
                            <StaticImage
                                className='logo'
                                alt=""
                                objectFit="contain"
                                width={100}
                                src={"../../../images/logos/sunray-venus.png"}
                            />
                        </Box>

                        <Typography display={['none', null, null, 'block']} component="h2"  variant= "homeH2" >
                            Act I:  The Void
                        </Typography>
                        <Box display='flex' flexDirection="column" alignItems='center'>
                            <Typography display={['block', null, null, 'none']} component="h2" variant="homeH2"  >
                                Act I:
                            </Typography>
                            <Typography display={['block', null, null, 'none']} component="h2" variant="homeH2" >
                                The Void
                            </Typography>
                        </Box>
                        
                    </Box>

                    {
                        size.height > 700 && 
                        <Box gap='60px' display={['none', null, null, 'flex']} px='2em'>
                            <Box flexBasis='50%'>

                                <Typography variant="body3" as='p' color="primary.light" >
                                    We begin in death. Maybe you are used to calling it space. It's not the silence after a story's last chapter, but the void that limns life, the emptiness that frames and encases substance - the cold on the other side of the skin of the world. Amid this absence, a presence surges. She is the universe. At the crest of her voice, uncountable stars bore holes in the dark. Their light enriches the nothingness between them.
                                </Typography >

                                <Typography variant="body3" as='p' color="primary.light" >
                                    For eons, she stands alone in her tower, looking out onto all that is. And then, one day, she becomes aware of her aloneness. She grows restless. She wanders into the garden at the tower’s base.
                                </Typography >

                                <Typography variant="body3" as='p' color="primary.light" >
                                    A second voice calls to the first. A newborn scorches the frame. They are the universe's complement, light pocked through with darkness, cooling stone swinging around the suns. They bring the vision by which the universe might learn to know herself. Imagine an eye, and the swath of the universe reflected in that eye, and the universe seeing her reflection inside the glassy organ that sees.
                                </Typography >

                            </Box>
                            <Box flexBasis='50%'>
                                <Typography variant="body3" as='p' color="primary.light">
                                    "What is this mind?"
                                </Typography >

                                <Typography variant="body3" as='p' color="primary.light">
                                    We'll call them Venus. They've found a crack in the firmament. They pry it open with claws of light and crawl through.
                                </Typography >

                                <Typography variant="body3" as='p' color="primary.light">
                                    Beneath them, the ocean yawns ahead, nipping at an unmarked shore. Behind them, the universe, draped in shadow, follows. For a moment, Venus and the universe hang in tandem, considering each other. The universe is overcome with a premonition: Venus devoured in the fiery jaws of some wild beast.
                                </Typography >

                                <Typography variant="body3" as='p' color="primary.light">
                                    Venus smiles. They wander into the waves. Just before they disappear beneath the surface, they beckon the universe to follow.
                                </Typography >

                                <Typography variant="body3" as='p' color="primary.light">
                                    The universe sees Venus in duplicate, two shimmering figures undulating in the surf. One figure tumbles into nothingness. The other darts deeper into the sea.
                                </Typography >

                            </Box>
                        </Box>
                    }

                
                </Box>
            </div>

            <div className="stars frame center-screen">
                <StaticImage
                    layout="fullWidth"
                    alt=""
                    placeholder='none'
                    loading='eager'
                    objectFit='cover'
                    src={"../../../images/chapters/two/stars-new.jpeg"}
                    formats={["auto"]}
                />
            </div>

            <div className="color frame center-screen">

            </div>

        </>
    )
}

export default ChapterTwo
