import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useWindowSize } from "../../../hooks/useWindowHeight";

import "./three.scss";

const ChapterThree = () => {
    const size = useWindowSize();
    return (

        <Box className='title-three frame center-screen' >
            <Box maxWidth='1300px' mx='auto'  >

                <Box display='flex' mb='1.5em' flexWrap='wrap' justifyContent='center'>
                    <Box flexBasis='100%' display='flex' mb='1em' justifyContent='center'>
                        <StaticImage
                            className='logo'
                            alt=""
                            objectFit="contain"
                            width={100}
                            src={"../../../images/logos/seahorse.png"}
                        />
                    </Box>


                    <Typography display={['none', null, null, 'block']} component="h2" variant="homeH2" >
                        Act II:  The Deep
                    </Typography>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        <Typography display={['block', null, null, 'none']} component="h2" variant="homeH2"  >
                            Act II:
                        </Typography>
                        <Typography display={['block', null, null, 'none']} component="h2" variant="homeH2" >
                            The Deep
                        </Typography>
                    </Box>
                </Box>

                {
                    size.height > 700 &&
                    <Box display={['none', null, null, 'flex']} gap='60px' px='2em'>
                        <Box flexBasis='50%'>


                            <Typography variant="body3" as='p' color="primary.light">
                                Beneath the emerald sea, Venus and the universe find a long-drowned city. The universe stands beneath a deteriorating archway. She has cast off her mantle of shadow, and radiates flame. Bubbles spray from her hair.
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                "Why do you come in pieces?"
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                The universe reaches for the fragmented Venus, a child's figure made from flakes of glass. Venus reaches back. When they clasp hands, the fire darts through Venus, too. They melt themself solid. The heat from their palms stirs the mounting foam.
                            </Typography >
                            <Typography variant="body3" as='p' color="primary.light">
                                While at first Venus saw themself as separate from the sea, an interloper in its depths, now they assume its flow. Steam jets open wherever they step. They populate the dead city. People of fire rise from their fingertips. The crumbling, kelp-strewn streets now bustle. Delighted with their creation, Venus chases the universe through the city, into the reefs, the light from their flamed heads alighting the coral. Their feet dance across shipwrecks studded with barnacles, the shark-bitten ribs of sailors. They move with all that's living and all that's dead.
                            </Typography >


                        </Box>
                        <Box flexBasis='50%'>


                            <Typography variant="body3" as='p' color="primary.light">
                                Venus is a child, and children like to vanish. In their delight, they wander past where the light of the sun can penetrate. Eyes of beings they cannot name watch them from the furrows of the earth. Tendrils grasp at their ankles. Creatures that have never known light gnash their teeth at this sudden interruption of flame.
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                But Venus is quick, and darts from them. To them, it is a game. They regard the monsters of the deep with a child's trust. They rest inside the mouth of a cave, where they amuse themself by shooting fiery plumes from their fingertips. The cave rumbles. The stalagmite at Venus's back is not an outcropping of rock, but a tooth. Just as the behemoth's mouth is about to close around them, the universe finds Venus and whisks them to the surface.
                            </Typography >


                        </Box>
                    </Box>
                }

              

                <div className="stars frame center-screen">
                    <StaticImage

                        layout="fullWidth"
                        alt=""
                        placeholder='none'
                        loading='eager'
                        objectFit='cover'
                        src={
                            "../../../images/chapters/two/deep-3.jpg"
                        }
                        formats={["auto"]}
                    />
                </div>

            </Box>
        </Box>
    )
}
export default ChapterThree
