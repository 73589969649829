import React from 'react';
import { useInView } from 'react-intersection-observer';
import 'intersection-observer';
import thresholdArray from '../../utils/thresholdArray';
import "./style.scss";


const Chapter = ({children, index}) => {
    // const { ref, inView, entry } = useInView({
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: thresholdArray(50),
    });

    const view = inView ? 'in-view' : 'out-of-view';
    // const view = entry.intersectionRatio > 0.4 ? 'in-view' : 'out-of-view';
    // console.log(entry)

    const wrapStyle = {
        display: inView ? "block" :'none'
    }



    return (
        <div ref={ref} className={`${view} chapter chapter-${index}`} data-index={index}>
            <div className="wrap" style={wrapStyle}>
                <div className="inner">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Chapter;