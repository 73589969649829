import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./one.scss";
import { useWindowSize } from "../../../hooks/useWindowHeight";

const ChapterOne = () => {
    const size = useWindowSize();
    const titleWidth = size.height > 700 ? 700 : 400
    return (
        <>
            <StaticImage

                layout="fullWidth"
                className='frame bkg'
                // objectFit='cover'
                alt=""
                src={
                    "../../../images/chapters/one/hero.jpg"
                }

            />
            <StaticImage

                layout="fullWidth"
                alt=""
                className='leaves'
                src={
                    "../../../images/chapters/one/leaves.png"
                }
                formats={["auto"]}
            />
            <div className="title frame center-screen" >
                {/* Any content here will be centered in the component */}
                <StaticImage

                    // layout="fullWidth"
                    alt=""
                    loading='eager'
                    backgroundColor='transparent'
                    placeholder='none'
                    // className='frame'
                    width={600}
                    objectFit='contain'
                    src={
                        "../../../images/chapters/one/title.png"
                    }
                    formats={["auto"]}
                />


            

            </div>
        </>
    )
}

export default ChapterOne;
