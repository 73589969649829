import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./five.scss";
import { Link } from 'gatsby'


const ChapterFive = () => (
    <>
        




        <Link className="stars frame" to='/video'>
            <StaticImage

                layout="fullWidth"
                alt=""
                placeholder='none'
                loading='eager'
                objectFit='contain'
                objectPosition={'top'}
                src={
                    "../../../images/video-thumbnails/sunray.jpeg"
                }
                formats={["auto"]}
            />
        </Link>
    </>





)

export default ChapterFive
