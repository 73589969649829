import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import "./four.scss";
import { useWindowSize } from "../../../hooks/useWindowHeight";

const ChapterFour = () => {
    const size = useWindowSize();
    return (
        <Box className='title-three frame center-screen'  >
            <Box maxWidth='1300px' mx='auto'  >

                <Box display='flex' mb='1.5em' flexWrap='wrap' justifyContent='center'>
                    <Box flexBasis='100%' display='flex' mb='1em' justifyContent='center'>
                        <StaticImage
                            className='logo'
                            alt=""
                            objectFit="contain"
                            width={100}
                            src={"../../../images/logos/the-fawn.png"}
                        />
                    </Box>

                

                    <Typography display={['none', null, null, 'block']} component="h2" variant="homeH2" >
                        Act III:  The Firmament
                    </Typography>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        <Typography display={['block', null, null, 'none']} component="h2" variant="homeH2"  >
                            Act III:
                        </Typography>
                        <Typography display={['block', null, null, 'none']} component="h2" textAlign='center' variant="homeH2" >
                            The Firmament
                        </Typography>
                    </Box>
                </Box>
                {
                    size.height > 700 &&
                    <Box display={['none', null, null, 'flex']} gap='60px' px='2em'>
                        <Box flexBasis='50%'>


                            <Typography variant="body3" as='p' color="primary.light">
                                The universe carries Venus to the sky. Again, she changes shape - no longer a torrent of fire, she is a warrior who wears a jeweled sword the color of dusk. Venus, too, has grown. Like the palace where they land, they are made from vapor, the edges of their form fraying into the surrounding cloud.
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                The days and nights whirl the palace around the globe. Venus and the universe watch the surface of the planet below as it changes. Continents lock into place. The people of fire become people of flesh and scatter across the earth. Venus and the universe share the love of gods, whose bonds are not constrained by time as mortals know it. Their love is both ancient and perennially incipient. It ages in the same moment it is born.
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                From their vantage, Venus and the universe watch love bloom across the earth. And they watch it die in fire and fracture, watch the tumult of what unfolds in its lack. There is so much pain beneath them, and in their observant circle, they fold the pain into their love, and scatter it back transformed.
                            </Typography >




                        </Box>
                        <Box flexBasis='50%'>

                            <Typography variant="body3" as='p' color="primary.light">
                                It cannot last. In time, the earth grows cold and the sun grows close. This is the time of perfect death from perfect love. Venus splits into their three aspects: Venus Caelestis, the glassy-eyed child, the morning star; Venus Physica, the sea-born creator who plays in the deep; and Venus Verticordia, the changer of hearts and transformer of love. As they shatter, they whisper to the universe a spell that will restore her to her beginning. At the end of her love, she will be young once more.
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                "Will you find me again?"
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                "It is my life's design to find you."
                            </Typography >

                            <Typography variant="body3" as='p' color="primary.light">
                                In the blankness of death, the deep incorruptible void, the cycle begins anew.
                            </Typography>


                        </Box>
                    </Box>
                }

              

                <div className="stars frame center-screen">
                    <StaticImage

                        layout="fullWidth"
                        alt=""
                        placeholder='none'
                        loading='eager'
                        objectFit='cover'
                        src={
                            "../../../images/chapters/two/clouds.jpg"
                        }
                        formats={["auto"]}
                    />
                </div>

            </Box>
        </Box>



    )
}

export default ChapterFour
