import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Chapter from "../components/Chapter"
import Chapter1 from "../components/Chapters/1/one"
import Chapter2 from "../components/Chapters/2/two"
import Chapter3 from "../components/Chapters/3/three"
import Chapter4 from "../components/Chapters/4/four"
import Chapter5 from "../components/Chapters/5/five"
import gsap from 'gsap'


const IndexPage = ({ transitionStatus }) =>{
  React.useEffect(() => {
    gsap.to('.chapter', {
      autoAlpha: 1,
      duration: 1,
    });
  }, []); //THIS IS RUN THE FIRST TIME THE SITE IS OPENED

  React.useEffect(() => {
    if (transitionStatus === 'entering') {
      gsap.to('.chapter', {
        autoAlpha: 1,
        duration: 1, //if we are entering the page, let's make the div with class .chapter visible in one second
      });
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.chapter', { autoAlpha: 0, duration: 1 }); //if we are exiting  the page, let's make the div with class .chapter transparent in one second
    }
  }, [transitionStatus]);

  return (
    <Layout currentPage={`home`}>
      <Seo title="Home" />
      <Chapter index="one" >
        < Chapter1 />
      </Chapter>
      <Chapter index="two" >
        < Chapter2 />
      </Chapter>
      <Chapter index="three" >
        < Chapter3 />
      </Chapter>
      <Chapter index="four" >
        < Chapter4 />
      </Chapter>
      <Chapter index="five" >
        < Chapter5 />
      </Chapter>

    </Layout>
  )
} 

export default IndexPage
